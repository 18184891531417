<template>
  <div class="row justify-content-center" v-loading="loading">
    <div class="col">
      <h3 class="text-muted">账号资料</h3>
      <ul class="list-unstyled ml-3">
        <li>
          <small class="text-muted"><small>公司名称</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.company_name!==updateInfo.company_name"><del>{{ accountInfo.company_name }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.company_name }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>企业性质</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.org_type!==updateInfo.org_type"><del>{{ accountInfo.org_type }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.org_type }}</h4>
        </li>
        <li v-if="accountInfo.org_district">
          <small class="text-muted"><small>所属地区</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.org_district!==updateInfo.org_district"><del>{{ accountInfo.org_district }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.org_district }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>国企单位</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.org_name!==updateInfo.org_name"><del>{{ accountInfo.org_name }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.org_name }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>联系人</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.contact_name!==updateInfo.contact_name"><del>{{ accountInfo.contact_name || '&lt;未填写&gt;' }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.contact_name || '&lt;未填写&gt;' }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>联系电话</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.contact_phone!==updateInfo.contact_phone"><del>{{ accountInfo.contact_phone || '&lt;未填写&gt;' }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.contact_phone || '&lt;未填写&gt;' }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>联系邮箱地址</small></small>
          <h5 class="mb-0 text-muted" v-if="accountInfo.contact_email!==updateInfo.contact_email"><del>{{ accountInfo.contact_email || '&lt;未填写&gt;' }}</del></h5>
          <h4 class="mb-2">{{ updateInfo.contact_email || '&lt;未填写&gt;' }}</h4>
        </li>
      </ul>
      <hr class="mb-3" />
      <h3 class="text-muted">审核结果</h3>
      <el-form :model="model" :rules="rules" :show-message="false" ref="form" class="ml-3">
        <div class="row">
          <div class="col">
            <el-form-item prop="status">
              <el-radio-group v-model="model.status">
                <el-radio label="1" class="radio-success">通过</el-radio>
                <el-radio label="2" class="radio-warning">拒绝</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div class="row mt-n3">
          <div class="col">
            <el-form-item prop="remark">
              <el-input type="textarea" v-model="model.remark" name="remark" placeholder="审核意见..."></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="row mt-3">
        <div class="col text-right">
          <a href="javascript:;" class="px-3 text-default" @click="cancelled">&lt; 取消</a>
          <el-button type="success" class="px-3" @click="submitForm">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'account-audit-form',
    props: {
      itemId: {
        type: Number,
        description: 'Model Id',
        default: null
      },
      saving: Boolean
    },
    data () {
      var checkStatus = (rule, value, cb) => {
        if (value === '1' || value === '2') cb()
        else cb(new Error('请选择审核结果'))
      }
      return {
        loading: false,
        accountInfo: {},
        updateInfo: {},
        model: {
          id: this.itemId,
          status: null,
          remark: ''
        },
        rules: {
          status: [{validator: checkStatus, trigger: 'blur'}]
        }
      }
    },
    methods: {
      fetchData () {
        this.loading = true
        this.axios.get('orgupdate/' + this.itemId).then((rep) => {
          if(rep.data.errcode === 0) {
            this.accountInfo = rep.data.data
            this.updateInfo = rep.data.org_update
            this.model.status = null
          } else {
            this.$notifyErr('加载数据失败', rep.data.errmsg)
            this.$emit('cancelled', true)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('加载数据失败', e.message)
          this.$emit('cancelled', true)
        })
      },
      cancelled () {
        this.$emit('cancelled', true)
      },
      submitForm () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.save()
          } else {
            return false
          }
        })
      },
      save () {
        this.$emit('update:saving', true)
        this.loading = true
        this.axios.put('orgupdate/audit', this.model).then((rep) => {
          if(rep.data.errcode === 0) {
            this.$emit('saved')
          } else {
            this.$notifyErr('提交数据失败', rep.data.errmsg)
          }
          this.$emit('update:saving', false)
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('提交数据失败', e.message)
          this.$emit('update:saving', false)
        })
      }
    },
    mounted () {
      if (this.itemId) {
        this.fetchData()
      }
    }
  }
</script>
<style scoped>
  .el-form-item.is-error .el-radio-group {
    border-bottom: 3px solid #da4a16;
  }
</style>